.nav-logo-container {
  img {
    width: 60%;
  }
}

.btn-primary {
  background-image: linear-gradient(45deg, #129b8d, #38ef7d);
  color: #fff !important;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 10px;
}

.socialicons {
  img {
    padding-right: 10px;
  }
}

.primary-text {
  color: #3a59a8;
}

.ptb-7 {
  padding: 70px 0px;
}

.footer {
  background-color: #fbfbfb;
}

a {
  color: #3a59a8 !important;
  text-decoration: none !important;
}

.social-icons {
  a {
    margin-left: 10px;
    img {
      width: 24px;
    }
  }
}

.themebtn {
  // position: fixed;
  // top: 50%;
  // right: 0;
  // transform: rotate(-90deg);
  padding: 5px !important;
  margin-left: 40px;
  background-color: #f8f8f8 !important;
  border: 0;
  border-radius: 5px;
  border: 1px solid transparent;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #000 !important;
    border: 1px solid #fff;
    transition: 0.6s ease;
    svg {
      color: #fff;
    }
  }
}

.primbtn {
  background: #3a59a8;
  color: #fff !important;
  padding: 12px 24px;
  font-family: "Cinzel";
  transition: 0.6s ease all;
  &:hover {
    background-color: #fff;
    color: #3a59a8 !important;
    border: 1px solid #ddd;
  }
}

.hero {
  // background-image: url("../img/bg.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  width: 100%;
}

.navbar-menu-container {
  color: #3a59a8 !important;
}
.footer {
  background-image: url("../img/footer.png");
  background-size: 100%;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  width: 100%;
  padding-top: 500px;
}

.bgSec {
  &.sec3padding {
    padding-bottom: 350px;
    background-color: #e9edf5;
  }
  &.cta {
    background-image: url("../img/home/cta.png");
    // background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.sec1 {
    background-image: url("../img/home/culinary.png");
    background-attachment: fixed;
  }
  .sec3 {
    background-image: url("../img/home/celebrate.png");
    background-repeat: no-repeat;
    height: 600px;
    background-attachment: fixed;
  }
  p {
    &.cursive {
      font-family: "Fasthand", cursive;
      font-size: 30px !important;
      line-height: 26px;
    }
  }
  .seccards {
    &.celeb {
      padding-top: 150px;
    }

    .item {
      position: relative;
      overflow: hidden;
      &.oasis {
        &:hover {
          .head {
            p {
              opacity: 0;
            }
            .visible {
              opacity: 1;
            }
          }
        }
      }
      .head {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 85%;
        left: 50%;
        .visible {
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        small,
        p {
          color: #fff;
          text-transform: uppercase;
        }
        p {
          font-size: 20px !important;
        }
      }
    }
  }
}

// Rooms SCSS

.mt-10 {
  margin-top: 100px;
}

.hotel-room {
  display: flex;
  border: 1px solid #efefef;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  // margin-top: 100px;

  .room-image {
    flex: 1;
    width: 40%;

    .carousel {
      .slide {
        img {
          object-fit: cover;
          height: 400px; // Adjust as needed
          width: 100%; // Ensure image fits the container
        }
      }

      .control-arrow {
        background: rgba(0, 0, 0, 0.5);
        width: 40px;
        height: 40px;
        position: absolute;
        top: 45%;

        &:before {
          border-width: 12px 12px 12px 0;
        }
        &:after {
          border-width: 12px 12px 12px 0;
        }

        &.control-prev {
          &:before {
            border-right-color: white; // Customize arrow color
            border-width: 5px 5px 5px 0;
            position: absolute;
            left: 12px;
            bottom: 13px;
          }
        }

        &.control-next {
          &:before {
            border-left-color: white; // Customize arrow color
            border-width: 5px 0px 5px 5px;
            position: absolute;
            left: 12px;
            bottom: 13px;
          }
        }
      }
    }
  }

  .room-details {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;

    .room-title {
      font-size: 34px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .room-description {
      font-size: 20px !important;
      margin-bottom: 30px;
    }

    .room-price {
      font-size: 20px;
      font-weight: bold;

      span {
        font-size: 16px;
        color: #888;
      }
      p {
        &.note {
          font-weight: normal;
          color: #888;
        }
      }
    }

    .room-amenities {
      margin-top: 20px;

      h5 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .amenity {
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 10px;
          gap: 10px;
          font-size: 24px;

          i {
            position: relative;
            top: -4px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.footer {
  ul {
    li {
      padding-bottom: 10px;
    }
  }
}

.contact-section {
  // padding: 40px 0;
  padding: 70px 0px;
  text-align: center;

  h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .contact-list {
    list-style: none;
    padding: 0;

    .contact-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-bottom: 15px;

      svg {
        margin-right: 10px;
        font-size: 24px;
        color: #3a59a8;
      }

      span {
        font-size: 18px;

        a {
          color: #3a59a8;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
