@media (max-width: 768px) {
  .section {
    padding: 50px 0px;
  }

  .mobcenter {
    text-align: center;
  }

  .mp-0 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .mb-none {
    display: none;
  }

  .bgSec {
    p {
      &.cursive {
        font-family: "Fasthand", cursive;
        font-size: 25px !important;
        line-height: 32px;
      }
    }
  }

  .hotel-room {
    flex-direction: column !important;
    .room-image {
      flex: 1;
      width: 100%;
    }
  }

  .carousel {
    .thumbs {
      text-align: center;
    }
    .room-details {
      padding-top: 0px;
    }
    .thumbs-wrapper {
      ul {
        li {
          width: 50px !important;
        }
      }
    }
  }

  .footer {
    padding-top: 50px;
  }

  .bgSec {
    .seccards {
      .item {
        padding-bottom: 20px;
        .head {
          top: 65%;
          .visible {
            bottom: -30px;
            opacity: 1;
          }
        }
      }
      &.celeb {
        padding-top: 20px;
      }
    }

    .sec3 {
      height: 100%;
    }

    &.sec3padding {
      padding-bottom: 0px;
    }
  }
}
